<template>
  <div>
    <SupportEmail
      :pre-subject="`[${$appConfig.customization.app.name} Support]`"
      :display-button="false"
      ref="supportHelpAndFeedback"
    ></SupportEmail>
    <v-menu offset-y transition="scroll-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn
          class="helpAndFeedback-menu-dropdown-header elevation-0"
          large
          v-on="on"
          :ripple="false"
        >
          <v-icon color="white">
            far fa-question-circle
          </v-icon>
          <v-icon
            color="white"
            x-small
            class="ml-3"
          >
            fas fa-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0 helpAndFeedback-menu-dropdown-container">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          class="selectorItems"
          @click="selectItem(item.text)"
          :ripple="false"
        >
          <v-list-item-title class="white--text">
            {{
              $t(item.text)
            }}
            <v-chip
              v-if="item.beta"
              color="orange"
              small
              outlined
              class="ml-2"
            >
              BETA
            </v-chip>
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon
              color="white"
              small
              class="ml-3"
              v-text="item.icon"
            ></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import SupportEmail from '../supportEmail/SupportEmail';

const i18nData = require('./helpAndFeedback.i18n');

export default {
  name: 'HelpAndFeedback',
  components: {
    SupportEmail,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const items = [];
      items.push(
        {
          text: 'support-email',
          icon: 'fas fa-envelope',
        }
      );
      // if(this.$appConfig.customization.app._id === 'DEFAULT') {
      //   items.push(
      //     {
      //       text: 'support-chat',
      //       icon: 'fas fa-comments',
      //       beta: true,
      //     }
      //   );
      // }
      // items.push(
      //   { text: 'replayTutorial', icon: 'fas fa-gamepad' }
      // );
      this.items = items;
    },
    /**
     * Open Email Support popup
     */
    openEmailSupport() {
      this.$refs.supportHelpAndFeedback.dialogSupportEmail = true;
    },
    /**
     * Open Chat Support popup
     */
    openChatSupport() {
      RocketChat(function() {
        this.showWidget();
        this.maximizeWidget();
      });
    },

    /**
     * To select item in the list (support)
     */
    selectItem(itemSelected) {
      if (itemSelected === 'support-email') {
        this.openEmailSupport();
      } else if (itemSelected === 'support-chat') {
        this.openChatSupport();
      }
    },
  },
};
</script>

<style lang="scss">
.helpAndFeedback-menu-dropdown-container {
  border-radius: 0 !important;
  min-width: 160px !important;

  .fa-chevron-down:before {
    font-size: 10px;
    padding-left: 10px;
  }

  .v-list-item__icon {
    margin: 11px 0 !important;
  }

  .selectorItems {
    min-height: 39px;
    padding: 0 24px !important;
  }
}

.helpAndFeedback-menu-dropdown-header {
  height: 50px !important;
  background-color: #14253e !important;
  border-radius: 0 !important;
  padding: 0 15px !important;
  min-width: 71.03px !important;
  border-top: solid 5px #14253e;
  outline: none !important;

  .v-btn__content {
    margin-top: -4px;
  }

  &:hover,
  &:focus {
    background-color: #2b3a55 !important;
    border-top: solid 5px #ff7d23;
  }
  &::before {
    opacity: 0 !important;
  }
}

.selectorItems {
  background-color: #14253e;
  border-right: solid 8px #14253e;
  &:hover,
  &:focus {
    background-color: #2b3a55 !important;
    border-right: solid 8px #ff7d23;
    border-radius: 0;
  }
}

.v-menu__content {
  border-radius: 0px !important;
}
</style>
