var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-basketMenu"
  }, [_c('router-link', {
    attrs: {
      "to": `/${this.$appConfig.currentBrand}/basket`,
      "id": "basket-menu"
    }
  }, [_c('v-badge', {
    attrs: {
      "bottom": "",
      "color": "orange font-weight-bold",
      "value": _vm.totalItems,
      "content": _vm.totalItems < 100 ? _vm.totalItems : '+99'
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-shopping-cart ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }