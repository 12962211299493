<template lang="html" src="./generalTermsAndConditions.template.vue"></template>

<style lang="scss" src="./generalTermsAndConditions.scss"></style>

<script>
import { ApiErrorParser, EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import Subscription from '../subscription/Subscription';
const i18nData = require('./generalTermsAndConditions.i18n');

export default {
  name: 'GeneralTermsAndConditions',
  components: { ApiErrorParser, EventBus, Subscription },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      dialog: false,
      dialogUpdate: false,
      updateCGU: false,
      updateCGV: false,
      user: null,
      selectedCGU: false,
      selectedCGV: false,
      disabled: true,
      callSubscriptionModal: false,
      validContracts: false,
      dateContractCGU: '2020-01-01',
      dateContractCGV: '2020-01-01',
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$apiInstance.getUser(this.$appConfig.user._id).then(
        (data) => {
          this.user = data;
          if (
            new Date(this.user.contracts.CGU) < new Date(this.dateContractCGU)
          ) {
            this.dialogUpdate = true;
            this.updateCGU = true;
          }
          if (
            new Date(this.user.contracts.CGV) < new Date(this.dateContractCGV)
          ) {
            this.dialogUpdate = true;
            this.updateCGV = true;
          }
          if (
            !this.updateCGV &&
            !this.updateCGU &&
            this.$appConfig.user.lastname === ' ' &&
            this.$appConfig.user.firstname === ' '
          ) {
            EventBus.$emit('displayUserIdentitySetterDialog');
          } else if (
            !this.updateCGV &&
            !this.updateCGU &&
            this.$appConfig.user.lastname &&
            this.$appConfig.user.firstname
          ) {
            this.callSubscriptionModal = true;
          } else {
            EventBus.$on('reloadUserIdentity', () => {
              this.callSubscriptionModal = true;
            });
          }
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
    checkChange() {
      this.validContracts = true;
      if (this.updateCGV && !this.selectedCGV) {
        this.validContracts = false;
      }
      if (this.updateCGU && !this.selectedCGU) {
        this.validContracts = false;
      }
    },
    validationContracts() {
      if (this.updateCGU) {
        this.modifyUserContractsCGU();
      } else if (this.updateCGV) {
        this.modifyUserContractsCGV();
      } else {
        this.getUser();
      }
    },
    modifyUserContractsCGU() {
      this.$apiInstance.modifyUserContractsCgu(this.$appConfig.user._id).then(
        () => {
          this.$notification.notify('SUCCESS',this.$t('updatedCGU'));
          this.updateCGU = false;
          if (!this.updateCGV) EventBus.$emit('displayUserIdentitySetterDialog');
          this.validationContracts();
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
    modifyUserContractsCGV() {
      this.$apiInstance.modifyUserContractsCgv(this.$appConfig.user._id).then(
        () => {
          this.$notification.notify('SUCCESS',this.$t('updatedCGV'));
          this.updateCGV = false;
          EventBus.$emit('displayUserIdentitySetterDialog');
          this.validationContracts();
        },
        (error) => {
          ApiErrorParser.parse(error);
        }
      );
    },
  },
};
</script>
