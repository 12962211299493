<template>
  <portal-skeleton v-if="loaded && $appConfig.isAuthenticated">
    <template v-slot:menu-header="{miniVariant}">
      <router-link
        :to="{name: 'Home'}"
      >
        <template v-if="miniVariant">
          <v-img
            :lazy-src="$appConfig.customization.app.logoSmall"
            height="90"
            width="300"
            contain
            :src="$appConfig.customization.app.logoSmall"
            alt="logo"
          ></v-img>
        </template>
        <template v-else>
          <v-img
            :lazy-src="$appConfig.customization.app.logoFull"
            max-height="90"
            max-width="300"
            contain
            :src="$appConfig.customization.app.logoFull"
            alt="logo"
          ></v-img>
        </template>
      </router-link>
      <v-list-item-subtitle class="text-center">
        <OrganizationSelector class="my-3 d-sm-flex d-md-none"></OrganizationSelector>
      </v-list-item-subtitle>
    </template>
    <template v-slot:menu-body>
      <MenuBCM
        :divided-menus-items="menuItems"
        :divider-color="'blue-grey darken-2'"
      ></MenuBCM>
    </template>
    <template v-slot:menu-footer="{ miniVariant }">
      <v-list>
        <v-list-item v-if="miniVariant">
          <v-icon
            class="ml-2"
            color="orange"
            small
          >
            fas fa-file-contract
          </v-icon>
        </v-list-item>
        <v-list-item v-show="!miniVariant">
          <!-- v-show is needed here because otherwise the dialog will close automatically when miniVariant is false -->
          <GeneralTermsAndConditions></GeneralTermsAndConditions>
        </v-list-item>
      </v-list>
    </template>
    <template v-slot:top-bar>
      <v-spacer></v-spacer>
      <OrganizationSelector class="mx-3 d-none d-md-flex"></OrganizationSelector>
      <v-divider vertical class="mx-0"></v-divider>
      <BasketMenu @created="updateBasketReference" ref="basketMenuRef"></BasketMenu>
      <v-divider vertical class="mx-0"></v-divider>
      <DownloadProgressBtn></DownloadProgressBtn>
      <v-divider vertical class="mx-0"></v-divider>
      <HelpAndFeedback></HelpAndFeedback>
      <v-divider vertical class="mx-0"></v-divider>
      <span class="d-flex">
        <i18n-selector></i18n-selector>
        <v-divider vertical class="mx-0"></v-divider>
        <UserMenu :user="$appConfig.user" :user-menu-items="userMenuItems"></UserMenu>
      </span>
    </template>
    <template v-slot:default>
      <!-- <live-chat
        department="BCM"
        :user="$appConfig.user"
        :language="$userLocale"
        ref="livechat"
      ></live-chat> -->
      <UserIdentitySetter></UserIdentitySetter>
      <Breadcrumbs></Breadcrumbs>
      <router-view></router-view>
    </template>
    <!-- Start of Rocket.Chat Livechat Script -->
  </portal-skeleton>
</template>

<script>
import Vue from 'vue';

import Hotjar from '@hotjar/browser';

import OrganizationSelector from '../../components/organizationSelector/OrganizationSelector';
import BasketMenu from '../../components/basketMenu/BasketMenu';
import Breadcrumbs from '../../components/breabcrumbs/Breadcrumbs';
import GeneralTermsAndConditions from '../../components/generalTermsAndConditions/GeneralTermsAndConditions';
import HelpAndFeedback from '../../components/helpAndFeedback/HelpAndFeedback';

import {
  ApiErrorParser,
  DownloadProgressBtn,
  I18nSelector,
  // LiveChat,
  LoginInformationTracker,
  MenuBCM,
  PortalSkeleton,
  UserIdentitySetter,
  UserMenu
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageIndex.i18n');

export default {
  name: 'PageIndex',
  components: {
    BasketMenu,
    Breadcrumbs,
    DownloadProgressBtn,
    GeneralTermsAndConditions,
    HelpAndFeedback,
    I18nSelector,
    // LiveChat,
    MenuBCM,
    OrganizationSelector,
    PortalSkeleton,
    UserIdentitySetter,
    UserMenu
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.$router.afterEach((to, from, next) =>{
      /**
       * Redirect to the current Brand UUID
       */
      if(this.loaded && this.$route.name === 'Home') {
        this.loaded = false;
        this.getBrand();
      }
    });

    this.getBackendTranslations();
    this.getBrand();
  },
  methods: {
    /**
     * GET BRAND
     */
    getBrand() {
      // console.log('PageIndex.vue:getBrand()');
      if (!this.$appConfig.currentBrand) {
        this.$router.push({name: 'Logout'});
        return;
      }
      /**
       * INITIALISATION HOT JAR
       */
      Hotjar.init(this.$appConfig.hotjarId, 6);
      /**
       * Get Brand
       */
      this.$apiInstance
        .getBrand(this.$appConfig.currentBrand)
        .then((brand) => {
          this.$appConfig.brand = brand;
          /**
           * Retrieve Saved Payments Methods
           */
          this.$apiInstance.getBrandPaymentMethods(this.$appConfig.currentBrand)
            .then(
              (paymentMethodsData) => {
                this.$appConfig.paymentMethods = paymentMethodsData;
              },
              (error) => {
                this.$appConfig.paymentMethods = [];
              }
            );
          /**
           * Retrieve Funding Instructions for Wire Transfer
           */
          this.$apiInstance.getBrandStripeFundingInstructions(this.$appConfig.currentBrand)
            .then(
              (fundingInstructionsData) => {
                this.$appConfig.fundingInstructions = fundingInstructionsData;
              },
              (error) => {
                this.$appConfig.fundingInstructions = [];
              }
            );

          /**
           * Retrieve Theme and load app
           */
          this.$apiInstance.getTheme(this.$appConfig.brand.features.theme)
            .then(
              (data) => {
                this.$appConfig.customization.app = data;
              },
              (error) => {
                this.$appConfig.customization.app = this.$appConfig.customization.login;
              }
            ).finally(()=>{
              /**
               * Verify domain
               * - If not the same domain, redirect to the proper domain
               * - Ignore redirect in development mode
               */
              if(this.$appConfig.customization.app.environment === 'development') {
                // eslint-disable-next-line
                console.info('Warehouse Theme redirection is disabled in development mode');
                if(this.$appConfig.customization.app.bcmURL !== document.location.origin) {
                  // eslint-disable-next-line
                  console.info(`-> Should be redirect to: ${this.$appConfig.customization.app.bcmURL}`);
                }else{
                  // eslint-disable-next-line
                  console.info('-> No redirection needed: warehouse theme and domain are the same');
                }
              }
              if(this.$appConfig.customization.app.environment !== 'development' && this.$appConfig.customization.app.bcmURL !== document.location.origin) {
                // eslint-disable-next-line
                console.log('Redirect to CMT Warehouse Theme location');
                document.location.replace(document.location.href.replace(document.location.origin, this.$appConfig.customization.app.bcmURL));
              }else{
                /**
                 * Load App
                 */
                this.loadPortalSkeleton();              
                this.loadPortalSkeleton();
                this.loadPortalSkeleton();              
              }
            });
        })
        .catch((error) => {
          /**
           * ERROR GET BRAND
           */
          this.$notification.notify(ApiErrorParser.parse(error));
          this.$route.push({name: 'Logout'});
        });
    },
    loadPortalSkeleton() {
      // console.log('PageIndex.vue:loadPortalSkeleton()');
      /**
       * INITIALISATION HOT JAR
       */
      Hotjar.init(this.$appConfig.hotjarId, 6);
      /**
       * HOT JAR information
       */
      Hotjar.identify(this.$appConfig.user._id, {
        first_name: this.$appConfig.user.firstname,
        signed_up_at: this.$appConfig.user.created,
        brand_id: this.$appConfig.brand._id,
        brand_name: this.$appConfig.brand.name,
        plan: this.$appConfig.brand.subscription.plan,
        lang: this.$userLocale,
      });
      /**
       * Create userMenu
       */
      this.userMenuItems = [
        {
          name: this.$t('UserMenuMyAccount'),
          icon: 'fas fa-cogs',
          route: {name: 'UserAccount'},
        },
        {
          name: this.$t('UserMenuLogOut'),
          icon: 'fas fa-sign-out-alt',
          route: {name: 'Logout'},
        },
      ];
      /**
       * Create menu
       */
      this.menuItems = [
        [
          {
            name: this.$t('menuItemsDashboard'),
            iconClass: 'far fa-calendar-check',
            fontSize: '18',
            fullPath: {name: 'Dashboard', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
        ],
        [
          {
            name: this.$t('menuItemsWarehouse'),
            iconClass: 'fas fa-warehouse',
            fontSize: '14',
            fullPath: {name: 'Warehouse', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
          {
            name: this.$t('menuItemsQuotes'),
            iconClass: 'fas fa-file-signature',
            fullPath: {name: 'Quotes', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
          {
            name: this.$t('menuItemsOrders'),
            iconClass: 'fas fa-box-open',
            fontSize: '15',
            fullPath: {name: 'Orders', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
          {
            name: this.$t('menuTraceability'),
            img: require('../../assets/scan.png'),
            fullPath: {name: 'Traceability', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
        ],
        [
          {
            name: this.$t('menuItemsAdmin'),
            iconClass: 'fas fa-users-cog',
            fullPath: {name: 'Administration', params: { brandUUID: `${this.$appConfig.currentBrand}`}},
          },
        ]
      ];
            
      /**
       * Load the portalSkeleton and redirect if /
       */
      if(this.$route.name === 'Home') {
        this.$router.push({name: 'Dashboard', params: {brandUUID: `${this.$appConfig.currentBrand}`}});
      }

      this.loaded = true;
      
      /**
       * Push login information
       */
      LoginInformationTracker.push(
        this.$apiInstance,
        this.$BcmModel,
        this.$appConfig.user,
        'industrial'
      );
      /**
       * Debug Saved Config
       */
      // console.log(this.$appConfig);
    },
    getBackendTranslations() {
      // console.log('PageIndex.vue:getBackendTranslations()');
      this.$apiInstance.getAllTechnologiesAndMaterialsCompatibilities().then(data => {
        for(const technologyObj of data) {
          for(const materialObj of technologyObj.materials) {
            for(const language of Object.keys(materialObj.name)) {
              const material = materialObj.material;
              const translation = {};
              translation[material] = materialObj.name[language];
              this.$root.$options.i18n.mergeLocaleMessage(language, translation);
            }
          }
        }
      });
    },
    updateBasketReference() {
      Vue.prototype.$basket = this.$refs.basketMenuRef;
    },
  }
};
</script>
