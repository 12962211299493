<template lang="html" src="./basketMenu.template.vue"></template>

<style lang="scss" src="./basketMenu.scss"></style>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./basketMenu.i18n');

export default {
  name: 'BasketMenu',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      totalItems: 0,
      basket: null,
    };
  },
  created() {
    this.getBasket();
  },
  mounted() {
    this.$emit('created');
  },
  methods: {
    addPartToBasket(partUUID, quantity) {
      const items = [];
      /**
       * We first update the basket in case it has been updated in another tab, for example
       */
      this.getBasket().then(() => {
        let found = false;
        for(const item of this.basket.items) {
          if(item.part._id === partUUID) {
            found = true;
            items.push({
              part: item.part._id,
              quantity: item.quantity + quantity
            });
          } else {
            items.push({
              part: item.part._id,
              quantity: item.quantity
            });
          }
        }

        if(!found) {
          items.push({
            part: partUUID,
            quantity: quantity
          });
        }

        const modifyUserBrandBasketBody = new this.$BcmModel.ModifyUserBrandBasketBody(
          items,
          this.basket.deliveryAddress ? this.$BcmModel.BasketDeliveryAddress.constructFromObject(this.basket.deliveryAddress) : null
        );

        this.$apiInstance.modifyUserBrandBasket(
          this.$appConfig.user._id,
          this.$appConfig.currentBrand,
          modifyUserBrandBasketBody
        ).then(
          (data) => {
            this.basket = data;
            this.$notification.notify('SUCCESS',this.$tc(
              'BasketAddPartSuccess',
              quantity,
              {
                name: this.basket.items.find(i => { return i.part._id === partUUID; }).part.name,
                quantity: quantity,
              }
            ));
            this.countAndUpdateBadge();
          },
          (error) => {
            /**
             * UNABLE TO SAVE THE BASKET
             */
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
      });
    },
    getBasket() {
      /**
       * We return a Promise to be able to do actions after the basket is updated
       */
      return new Promise((resolve, reject) => {
        this.$apiInstance.getUserBrandBasket(
          this.$appConfig.user._id,
          this.$appConfig.brand._id
        ).then(data => {
          this.basket = data;
          this.countAndUpdateBadge();
          resolve();
        }).catch(err => {
          reject(err);
        });
      });
    },
    countAndUpdateBadge() {
      this.totalItems = (this.basket?.items.map(i => {return i.quantity;}) || []).reduce((a, b) => {return a + b;}, 0);
    },
  },
};
</script>
