<template lang="html" src="./subscription.template.vue"></template>

<style lang="scss" src="./subscription.scss"></style>

<script>
import { ApiErrorParser, EventBus, StripeSavePayment } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./subscription.i18n');

export default {
  name: 'Subscription',
  components: { ApiErrorParser, EventBus, StripeSavePayment },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      endOfTrialPeriod: null,
      contractsValidate: false,
      paymentMethodDefault: '',
      disableAddCardDefault: false,
      modalAddPayment: false,
      messageSuccess: false,
      addPaymentKey: `payment-${Date.now()}`,
      priceToDisplay: null,
      trialPeriod: null,
    };
  },
  created() {
    EventBus.$on('cardAdded', () => {
      this.messageSuccess = true;
    });
    EventBus.$off('cardAdded');
    this.prepareSubscriptionDisplay();
  },
  methods: {
    /**
     * SUBSCRIPTION DISPLAY INITIALIZATION
     */
    prepareSubscriptionDisplay() {
      const validContracts = this.$appConfig.user.contracts.CGU !== null && this.$appConfig.user.contracts.CGV !== null;
      if(validContracts 
        && this.$appConfig.brand.subscription.payment_method === 'CARD'
        && this.$appConfig.brand.subscription.price > 0) {
        /**
         * Verify default payment method exists
         */
        this.$apiInstance
          .getBrandDefaultPaymentMethod(this.$appConfig.currentBrand)
          .then(
            (data) => {
              if (!data) {
                /**
                 * No default payment method found
                 */
                this.modalAddPayment = true;
              }
            }
          );
      }
      let bestDiscount = 0;
      if (this.$appConfig.brand.subscription.discounts) {
        const discounts = Object.keys(this.$appConfig.brand.subscription.discounts);
        for (const discount of discounts) {
          if (parseInt(discount) > bestDiscount) {
            bestDiscount = parseInt(discount);
          }
        }
      }
      this.priceToDisplay = this.$appConfig.brand.subscription.price - (bestDiscount / 100) * this.$appConfig.brand.subscription.price;
      this.priceToDisplay = Math.round(
        Math.round(this.priceToDisplay * 100) / 100
      ).toFixed(2);

      if (this.$appConfig.brand.subscription.trial_period > 0) {
        this.trialPeriod = this.$appConfig.brand.subscription.trial_period;
        this.endOfTrialPeriod = new Date();
        this.endOfTrialPeriod = new Date(
          this.endOfTrialPeriod.setDate(
            this.endOfTrialPeriod.getDate() +
              this.trialPeriod +
              1 // Because the invoice is created at midnight (12pm)
          )
        );
      }
    },
    getSubscriptionTitle(plan) {
      /**
       * Temporary fix for subscription until the attribute title will be available
       */
      let title = '';
      switch (plan) {
      case 'CMT':
        title = 'CMT';
        break;
      case 'ORGANIZATION_MONTHLY':
        title = 'CMT Organization Plus';
        break;
      case 'ORGANIZATION_YEARLY':
        title = 'CMT Organization Plus';
        break;
      case 'PRODUCTION_MONTHLY':
        title = 'CMT Production Plus';
        break;
      case 'PRODUCTION_YEARLY':
        title = 'CMT Production Plus';
        break;
      case 'ENTERPRISE_MONTHLY':
        title = 'CMT Enterprise';
        break;
      case 'ENTERPRISE_YEARLY':
        title = 'CMT Enterprise';
        break;
      default:
        title = plan.replace('_',' ');
        break;
      }
      return title;
    },
    saveCard() {
      EventBus.$on('requestForAddCard', () => {
        this.disableAddCardDefault = true;
      });
      this.getBrand();
      this.$refs.stripeSavePayment.loading = false;
    },
    errorSave() {
      this.$refs.stripeSavePayment.loading = false;
      this.modalAddPayment = true;
    },
    getReadableDate(dateObject) {
      const day = dateObject.getDate();
      const month = dateObject.getMonth();
      const year = dateObject.getFullYear();
      const months = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
      ];
      return day + ' ' + this.$t(months[month]) + ' ' + year;
    },
  },
};
</script>
