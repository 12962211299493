<template lang="html" src="./organizationSelector.template.vue"></template>

<style lang="scss" src="./organizationSelector.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./organizationSelector.i18n');

export default {
  name: 'OrganizationSelector',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: true,
      managedBrand: false,
      name: null,
    };
  },
  created() {
    EventBus.$on('brandReloaded', this.init);
    EventBus.$on('refreshAuthToken', this.init);
    this.init();
  },
  methods: {
    init() {
      if (this.$appConfig?.brand) {
        this.name = this.$appConfig.brand.name;
        /**
         * Detect if the current brand is a managed brand
         * If the current brand is not found in the userBrands list, in this case the current brand is a managed brand
         */
        let found = false;
        this.$appConfig.userBrands.forEach((brand) => {
          if (brand._id === this.$appConfig.currentBrand) {
            found = true;
          }
        });
        this.managedBrand = !found;
        this.loading = false;
      }
    },
    orgChange(value) {
      /**
       * Emit event globalOrganizationSelectorOrganizationChanged
       */
      this.$appConfig.currentBrand = value;
      EventBus.$emit('globalOrganizationSelectorOrganizationChanged', value);
    },
  },
};
</script>
