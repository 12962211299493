var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SupportEmail', {
    ref: "supportHelpAndFeedback",
    attrs: {
      "pre-subject": `[${_vm.$appConfig.customization.app.name} Support]`,
      "display-button": false
    }
  }), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "transition": "scroll-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "helpAndFeedback-menu-dropdown-header elevation-0",
          attrs: {
            "large": "",
            "ripple": false
          }
        }, on), [_c('v-icon', {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(" far fa-question-circle ")]), _c('v-icon', {
          staticClass: "ml-3",
          attrs: {
            "color": "white",
            "x-small": ""
          }
        }, [_vm._v(" fas fa-chevron-down ")])], 1)];
      }
    }])
  }, [_c('v-list', {
    staticClass: "pa-0 helpAndFeedback-menu-dropdown-container"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.title,
      staticClass: "selectorItems",
      attrs: {
        "ripple": false
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(item.text);
        }
      }
    }, [_c('v-list-item-title', {
      staticClass: "white--text"
    }, [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " "), item.beta ? _c('v-chip', {
      staticClass: "ml-2",
      attrs: {
        "color": "orange",
        "small": "",
        "outlined": ""
      }
    }, [_vm._v(" BETA ")]) : _vm._e()], 1), _c('v-list-item-icon', [_c('v-icon', {
      staticClass: "ml-3",
      attrs: {
        "color": "white",
        "small": ""
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }