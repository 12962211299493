var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-organizationSelector text-center"
  }, [_vm.$appConfig.userBrands.length === 0 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('OrganizationSelectorError')) + " ")]) : _vm._e(), _vm.loading ? _c('div') : _vm.managedBrand ? [_c('span', {
    staticClass: "white--text col-12 text-center font-weight-regular pt-4 body-2",
    staticStyle: {
      "letter-spacing": "0.1rem !important"
    }
  }, [_c('v-btn', {
    staticClass: "white--text mr-3",
    attrs: {
      "title": _vm.$t('exit'),
      "fab": "",
      "x-small": "",
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.orgChange(_vm.$appConfig.userBrands[0]._id);
      }
    }
  }, [_c('v-icon', {
    staticClass: "fa-rotate-180",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-sign-out-alt")])], 1), _c('v-icon', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v("fas fa-building")]), _vm._v(" " + _vm._s(_vm.$t('management')) + " " + _vm._s(_vm.name) + " ")], 1)] : [_vm.$appConfig.userBrands.length === 1 ? _c('span', {
    staticClass: "white--text col-12 text-center font-weight-regular pt-4 body-2",
    staticStyle: {
      "letter-spacing": "0.1rem !important"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v("fas fa-building")]), _vm._v(" " + _vm._s(_vm.$t('OrganizationLabel')) + " " + _vm._s(_vm.name) + " ")], 1) : [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-building ")]), _c('v-overflow-btn', {
    staticClass: "caption form",
    attrs: {
      "solo": "",
      "dense": "",
      "prefix": `${_vm.$t('OrganizationLabel')}`,
      "items": _vm.$appConfig.userBrands,
      "item-text": "name",
      "item-value": "_id",
      "value": _vm.$appConfig.currentBrand
    },
    on: {
      "change": _vm.orgChange
    }
  })]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }