var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded && _vm.$appConfig.isAuthenticated ? _c('portal-skeleton', {
    scopedSlots: _vm._u([{
      key: "menu-header",
      fn: function (_ref) {
        var miniVariant = _ref.miniVariant;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'Home'
            }
          }
        }, [miniVariant ? [_c('v-img', {
          attrs: {
            "lazy-src": _vm.$appConfig.customization.app.logoSmall,
            "height": "90",
            "width": "300",
            "contain": "",
            "src": _vm.$appConfig.customization.app.logoSmall,
            "alt": "logo"
          }
        })] : [_c('v-img', {
          attrs: {
            "lazy-src": _vm.$appConfig.customization.app.logoFull,
            "max-height": "90",
            "max-width": "300",
            "contain": "",
            "src": _vm.$appConfig.customization.app.logoFull,
            "alt": "logo"
          }
        })]], 2), _c('v-list-item-subtitle', {
          staticClass: "text-center"
        }, [_c('OrganizationSelector', {
          staticClass: "my-3 d-sm-flex d-md-none"
        })], 1)];
      }
    }, {
      key: "menu-body",
      fn: function () {
        return [_c('MenuBCM', {
          attrs: {
            "divided-menus-items": _vm.menuItems,
            "divider-color": 'blue-grey darken-2'
          }
        })];
      },
      proxy: true
    }, {
      key: "menu-footer",
      fn: function (_ref2) {
        var miniVariant = _ref2.miniVariant;
        return [_c('v-list', [miniVariant ? _c('v-list-item', [_c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "color": "orange",
            "small": ""
          }
        }, [_vm._v(" fas fa-file-contract ")])], 1) : _vm._e(), _c('v-list-item', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !miniVariant,
            expression: "!miniVariant"
          }]
        }, [_c('GeneralTermsAndConditions')], 1)], 1)];
      }
    }, {
      key: "top-bar",
      fn: function () {
        return [_c('v-spacer'), _c('OrganizationSelector', {
          staticClass: "mx-3 d-none d-md-flex"
        }), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('BasketMenu', {
          ref: "basketMenuRef",
          on: {
            "created": _vm.updateBasketReference
          }
        }), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('DownloadProgressBtn'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('HelpAndFeedback'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('span', {
          staticClass: "d-flex"
        }, [_c('i18n-selector'), _c('v-divider', {
          staticClass: "mx-0",
          attrs: {
            "vertical": ""
          }
        }), _c('UserMenu', {
          attrs: {
            "user": _vm.$appConfig.user,
            "user-menu-items": _vm.userMenuItems
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('UserIdentitySetter'), _c('Breadcrumbs'), _c('router-view')];
      },
      proxy: true
    }], null, false, 1993466919)
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }