var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-subscription"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.modalAddPayment,
      callback: function ($$v) {
        _vm.modalAddPayment = $$v;
      },
      expression: "modalAddPayment"
    }
  }, [_vm.messageSuccess === false ? _c('v-card', {
    attrs: {
      "min-height": "250px",
      "disabled": _vm.disableAddCardDefault
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t('AddCard')) + " "), _c('v-spacer')], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.$appConfig.brandUserRoles.includes('admin') ? _c('v-card-text', [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('infoAddCard', {
        offerSelected: _vm.getSubscriptionTitle(_vm.$appConfig.brand.subscription.plan),
        recurrence: _vm.$t(_vm.$appConfig.brand.subscription.recurrence)
      }))
    }
  }), _c('p', [_vm.trialPeriod ? _c('strong', [_vm._v(" " + _vm._s(_vm.$t('invoiceCreatingAfterTrialPeriod1', {
    offerSelected: _vm.priceToDisplay
  })) + " " + _vm._s(_vm.getReadableDate(_vm.$appConfig.brand.subscription.nextBillingDate ? new Date(_vm.$appConfig.brand.subscription.nextBillingDate) : _vm.endOfTrialPeriod)) + _vm._s(_vm.$t('invoiceCreatingAfterTrialPeriod2', {
    trialPeriod: _vm.trialPeriod
  })) + " ")]) : _c('strong', [_vm._v(" " + _vm._s(_vm.$t('invoiceCreatingAfterPaid', {
    offerSelected: _vm.priceToDisplay
  })) + " ")])]), _c('p', [_vm._v(_vm._s(_vm.$t('manageSubscription')))]), _c('p', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t('VatApply')) + " ")])]), _c('v-divider', {
    staticClass: "my-4"
  }), _vm.$stripeConfig.pk ? _c('StripeSavePayment', {
    key: _vm.addPaymentKey,
    ref: "stripeSavePayment",
    attrs: {
      "api-key": _vm.$stripeConfig.pk,
      "brand-i-d": _vm.$route.params.brandUUID
    },
    on: {
      "save": _vm.saveCard,
      "errorSave": _vm.errorSave
    }
  }) : _vm._e()], 1) : _c('v-card-text', [_c('v-alert', {
    attrs: {
      "width": "100%",
      "type": "info",
      "prominent": "",
      "outlined": ""
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('NeedAdminIntervention1')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t('NeedAdminIntervention2')) + " ")])]), _c('p', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "to": {
        name: 'Logout'
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("Logout")) + " ")], 1)], 1)], 1)], 1) : _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-icon', {
    staticClass: "iconThumbs",
    attrs: {
      "color": "success"
    }
  }, [_vm._v(" far fa-thumbs-up ")]), _c('v-spacer')], 1), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-card-text', [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('wellDone'))
    }
  }), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('youCanUseBcm')) + " ")])])]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modalAddPayment = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-times ")]), _vm._v(" " + _vm._s(_vm.$t('close')) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }